import React, { useRef, useEffect } from "react";
import { Text, Icon } from "@atoms";
import { useAppState } from "@state";
import { AnimatePresence, m, useScroll } from "framer-motion";
import { toggleBodyScroll } from "@utils";

const TranscriptModal = () => {
  const ref = useRef();
  const { scrollYProgress } = useScroll({
    container: ref,
  });
  const [{ transcript }, dispatch] = useAppState();
  const { transcript: active, heading, subheading, copy } = transcript;

  useEffect(() => {
    toggleBodyScroll(!active);

    if (active) {
      document.documentElement.classList.add("is-locked");
    } else {
      document.documentElement.classList.remove("is-locked");
    }
  }, [active]);

  return (
    <AnimatePresence>
      {active && (
        <m.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-[900] flex h-screen w-full flex-col justify-between bg-black/90"
        >
          <div className="relative z-10 flex h-screen flex-col overflow-hidden p-5 pb-0 sm:p-10 md:p-20">
            <Text variant="h2" className="mb-10 text-white">
              {heading}
            </Text>
            <Text variant="h3" className="-mt-10 mb-10 text-white">
              {subheading}
            </Text>
            <div className="relative h-[calc(100%-5rem)] md:h-full">
              <div
                ref={ref}
                className="h-full w-full max-w-xl flex-grow overflow-auto pb-[10rem]"
              >
                <Text className="prose text-white" richText>
                  {copy}
                </Text>
              </div>
              <div className="absolute bottom-[10rem] right-0 top-0 w-1 rounded-full bg-white">
                <m.div
                  style={{ scaleY: scrollYProgress }}
                  className="absolute inset-0 origin-top bg-coral"
                />
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 left-0 right-0 z-20 flex h-16 w-full flex-nowrap items-center justify-center border-t-3 border-white bg-black/75 text-white">
            <button
              type="button"
              className="p-4"
              aria-label="Close the transcript"
              onClick={() => {
                dispatch({ type: "closeTranscript" });
              }}
            >
              <Icon name="close" className="w-6" />
            </button>
          </div>
        </m.div>
      )}
    </AnimatePresence>
  );
};

TranscriptModal.defaultProps = {};

export default TranscriptModal;
